import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Dashboard from "./components/pages/Dashboard";
import React, { Component } from "react";
import Login from "./components/auth/Login";
import NotFound from "./components/layout/NotFound";
import { Provider } from "react-redux";
import PrivateRoute from "./components/private-route/PrivateRoute";
import Register from "./components/auth/Register";
import store from "./store";
import jwt_decode from "jwt-decode";
import setAuthToken from "./utils/setAuthToken";
import { setCurrentUser, logoutUser } from "./actions/authActions";

import "./App.css";
import "../node_modules/bootstrap/dist/css/bootstrap.css";
import "../node_modules/bootstrap/dist/js/bootstrap";
import "../node_modules/font-awesome/css/font-awesome.css";
import "../node_modules/jquery/dist/jquery.min";
import "../node_modules/popper.js/dist/popper";
import User from "./components/pages/Users";

import Form from "./components/pages/Farms";
import Pool from "./components/pages/Pools";
import Ipaddess from "./components/pages/Ipaddess";
import Stake from "./components/pages/Stake";
import ManageSale from "./components/pages/ManageSale";
import Launchpad from "./components/pages/LaunchPad";
import Blocklist from "./components/pages/blocklist";
import About from "./components/pages/About";
import Landing from "./components/pages/Landing";
// import homepages from "./components/pages/homepages";
import Homeimage from "./components/pages/Homeimage";
import Homepages from "./components/pages/Homepages";
import Setting from "./components/pages/Setting";
import News from "./components/pages/News";
import NewsDes from "./components/pages/Newsdes";
import ContactUs from "./components/pages/ContactUs";
import Artical from "./components/pages/Artical";
import ArticlesImage1 from "./components/pages/ArticleImage1";
import ArticlesImage2 from "./components/pages/ArticlesImage2";
import ArticlesImage3 from "./components/pages/ArticleImage3";
import ArticlesImage4 from "./components/pages/ArticleImage4";
import Cms from "./components/pages/cms";
import Roadmaplist from "./components/pages/Roadmap/roadmaplist";
import Roadmapedit from "./components/pages/Roadmap/roadmapedit";
import Roadmapadd from "./components/pages/Roadmap/roadmapadd";

import Addsociallink from "./components/pages/Sociallink/addsociallink";
import Editsociallink from "./components/pages/Sociallink/editsociallink";
import Sociallist from "./components/pages/Sociallink/sociallinklist";

import Aboutlist from "./components/pages/About/Aboutlist";
import Aboutedit from "./components/pages/About/Aboutedit";
import Aboutadd from "./components/pages/About/Aboutadd";

import Paradigmlist from "./components/pages/Paradigm/Paradigmlist";
import Paradigmedit from "./components/pages/Paradigm/Paradigmedit";
import Paradigmadd from "./components/pages/Paradigm/Paradigmadd";

import Philosophylist from "./components/pages/Philosophy/Philosophylist";
import Philosophyedit from "./components/pages/Philosophy/Philosophyedit";
import Philosophyadd from "./components/pages/Philosophy/Philosophyadd";

import Charterlist from "./components/pages/Charter/Charterlist";
import Charteredit from "./components/pages/Charter/Charteredit";
import Charteradd from "./components/pages/Charter/Charteradd";

if (localStorage.jwtToken) {
  const token = localStorage.jwtToken;
  setAuthToken(token);
  const decoded = jwt_decode(token);
  store.dispatch(setCurrentUser(decoded));
  const currentTime = Date.now() / 1000;
  if (decoded.exp < currentTime) {
    store.dispatch(logoutUser());
    window.location.href = "./login";
  }
}

class App extends Component {
  render() {
    return (
      <Provider store={store}>
        <Router basename="">
          <div className="App">
            <Switch>
              <Route exact path="/" component={Login} />
              <Route exact path="/register" component={Register} />
              <Route exact path="/login" component={Login} />
              <Switch>
                <PrivateRoute exact path="/Dashboard" component={Dashboard} />
                <PrivateRoute exact path="/Users" component={User} />
                <PrivateRoute exact path="/featruedes" component={Ipaddess} />
                <PrivateRoute exact path="/Articles" component={Artical} />
                <PrivateRoute exact path="/Feature" component={Pool} />
                <PrivateRoute exact path="/Stake" component={Stake} />
                <PrivateRoute
                  exact
                  path="/ManageSale/:id"
                  component={ManageSale}
                />
                <PrivateRoute exact path="/ManageSale" component={ManageSale} />
                <PrivateRoute exact path="/LaunchPad" component={Launchpad} />
                <PrivateRoute exact path="/About" component={About} />
                <PrivateRoute exact path="/Home" component={Landing} />

                <PrivateRoute exact path="/Homepages" component={Homepages} />
                <PrivateRoute exact path="/Homeimage" component={Homeimage} />
                <PrivateRoute exact path="/Setting" component={Setting} />
                <PrivateRoute exact path="/News" component={News} />
                <PrivateRoute
                  exact
                  path="/Newsdescription"
                  component={NewsDes}
                />
                <PrivateRoute exact path="/ContactUs" component={ContactUs} />
                <PrivateRoute
                  exact
                  path="/Articles1"
                  component={ArticlesImage1}
                />
                <PrivateRoute
                  exact
                  path="/Articles2"
                  component={ArticlesImage2}
                />
                <PrivateRoute
                  exact
                  path="/Articles3"
                  component={ArticlesImage3}
                />
                <PrivateRoute
                  exact
                  path="/Articles4"
                  component={ArticlesImage4}
                />
                {/* <PrivateRoute exact path="/blocklist" component={Blocklist} /> */}
                <PrivateRoute exact path="/cms" component={Cms} />
                <PrivateRoute exact path="/roadmapadd" component={Roadmapadd} />

                <PrivateRoute
                  exact
                  path="/roadmaplist"
                  component={Roadmaplist}
                />
                <PrivateRoute
                  exact
                  path="/roadmapedit"
                  component={Roadmapedit}
                />

                <PrivateRoute exact path="/aboutadd" component={Aboutadd} />

                <PrivateRoute exact path="/aboutlist" component={Aboutlist} />
                <PrivateRoute exact path="/aboutedit" component={Aboutedit} />

                <PrivateRoute
                  exact
                  path="/paradigmadd"
                  component={Paradigmadd}
                />

                <PrivateRoute
                  exact
                  path="/paradigmlist"
                  component={Paradigmlist}
                />
                <PrivateRoute
                  exact
                  path="/paradigmedit"
                  component={Paradigmedit}
                />

                <PrivateRoute
                  exact
                  path="/philosophyadd"
                  component={Philosophyadd}
                />

                <PrivateRoute
                  exact
                  path="/philosophylist"
                  component={Philosophylist}
                />
                <PrivateRoute
                  exact
                  path="/philosophyedit"
                  component={Philosophyedit}
                />

                <PrivateRoute exact path="/charteradd" component={Charteradd} />

                <PrivateRoute
                  exact
                  path="/charterlist"
                  component={Charterlist}
                />
                <PrivateRoute
                  exact
                  path="/charteredit"
                  component={Charteredit}
                />

                <PrivateRoute
                  exact
                  path="/sociallinklist"
                  component={Sociallist}
                />
                <PrivateRoute
                  exact
                  path="/Addsociallink"
                  component={Addsociallink}
                />
                <PrivateRoute
                  exact
                  path="/Editsociallink"
                  component={Editsociallink}
                />
              </Switch>
              <Route exact path="*" component={NotFound} />
            </Switch>
          </div>
        </Router>
      </Provider>
    );
  }
}
export default App;
